































































































































@import "node_modules/element-ui/packages/theme-chalk/src/common/var.scss";
@import "@/assets/css/project-variables";

.report-rss-table{
  .centered-col{
    text-align: center !important;
  }
  .bold-col{
    font-weight: bold !important;
  }
  .color-1-col{
    background-color: #c2ddee;
  }
  .color-2-col{
    background-color: #ECECEC;
  }
}

.report-rss-header{
  th{
    color: #141414 !important;
    background-color: $--color-warning !important;
    vertical-align: top !important;
    font-weight: bold !important;
    text-align: center !important;
  }
}
